import React from 'react';
import './App.css';  // Importa el archivo de estilos

const App = () => {
  const visitarSitio = () => {
    window.location.href = 'https://globalnexttrade.com';
  };

  return (
    <div className="container">
      <div className="content">
        <img src="/logo.png" alt="Logo de la compañía" className="logo" />
        <h1>Nos mudamos, ahora nos pueden encontrar en el siguiente URL</h1>
        <button className="visit-button" onClick={visitarSitio}>Visitar el nuevo sitio web</button>
      </div>
    </div>
  );
}

export default App;
